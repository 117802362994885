.top-navbar {
  @apply border-b border-gray-200;
  min-height: 70px;
}
.app[data-theme="dark"] .top-navbar {
  @apply border-gray-600;
}
.app[data-theme="dark"] .top-navbar .dropdown-profile ul {
  @apply border-gray-600;
}

.social {
  padding-right: 0.5rem;
  padding-top: 2px;
  border-left: none;
  border-top: none;
  border-bottom: none;
}

.social svg,
.social-link svg {
  stroke: #570df8;
}
.app[data-theme="dark"] .social svg,
.app[data-theme="dark"] .social-link svg {
  stroke: #25b2a6;
}
