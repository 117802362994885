.curator {
  @apply pr-2  pt-[2px];
  border-left: none;
  border-top: none;
  border-bottom: none;
}
.curator .theme-border {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
