.profit-list {
  @apply bg-base-100 rounded-md shadow p-5 mb-5 xl:w-1/2;
  min-height: 300px;
}
.profit-list.empty {
  @apply flex justify-center items-center;
}

.profit-list .table th:first-child {
  position: unset;
}
