.tabulation {
  @apply flex justify-between lg:gap-3 mb-5  flex-col lg:flex-row;
}
.tabulation .tabulation-item {
  @apply w-full text-center rounded-md px-3 py-1 cursor-pointer flex justify-between items-center gap-3 lg:block;
}
.tabulation-item.active,
.tabulation-item:hover {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
}
